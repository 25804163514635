import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

export const FormInput = ({
  label,
  type,
  placeholder,
  formProps = {},
  value,
  inputOnChange,
  name,
  padding = { base: "0 10px 10px 0", md: "0 20px 20px 0" },
  readOnly,
  errorMessage = "",
  accept = "",
  resourceRef,
  isDisabled,
  inputProps = {},
}) => {
  return (
    <FormControl padding={padding} colorScheme="brand" {...formProps}>
      <FormLabel
        fontSize="15px"
        textTransform="capitalize"
        fontWeight={!!formProps.fontWeight ? formProps.fontWeight : ""}
      >
        {label}
      </FormLabel>
      <Input
        type={type}
        placeholder={placeholder}
        value={type !== "file" ? value || "" : value}
        onChange={inputOnChange}
        errorBorderColor="red.300"
        name={name}
        readOnly={readOnly}
        accept={accept}
        ref={resourceRef}
        isDisabled={isDisabled}
        {...inputProps}
      />
      <FormErrorMessage display="block">{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
