import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Glober, sans-serif",
  },
  colors: {
    brand: {
      50: "#e1f3ff",
      100: "#b7dafb",
      200: "#8cbff2",
      300: "#60a5ea",
      400: "#368ce3",
      500: "#1c72c9",
      600: "#11599e",
      700: "#083f72",
      800: "#002647",
      900: "#000e1d",
    },
  },
  // Modal: { sizes: { xl: { Content: { maxWidth: "90rem" }, }, }, }
});

export default theme;
