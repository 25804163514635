import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useToast, useRadioGroup } from "@chakra-ui/react";
import { format } from "date-fns";
import { QAPP } from "../../graphql/queries";
import { GENERATEQSET } from "../../graphql/mutations";
import {
  getAvgTimeTakenPerQuestion,
  secondsToMinutesAndSeconds,
} from "../../utils/helper";

export const useHome = ({ user, loadingCallback }) => {
  const [myApp, setMyApp] = useState({});
  const [qsets, setQsets] = useState([]);
  const [myBadges, setMyBadges] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [lastQsetStarted, setLastQsetStarted] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { search } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(search);
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");

  const toast = useToast();

  const [getMyApp] = useLazyQuery(QAPP, {
    onCompleted: (data) => {
      setMyApp(data?.qapp);
      setLastQsetStarted(
        data?.qapp?.qsets?.length > 0 ? data?.qapp?.qsets[0] : {},
      );
      setQsets(
        data?.qapp?.qsets.map((qset) => {
          const submission =
            qset?.qSetSubmissions.length > 0 ? qset?.qSetSubmissions[0] : {};
          const totalQuestions = submission.id
            ? submission?.qset?.dynosUnderQSet.length
            : null;
          return {
            ...qset,
            name: qset?.qSetName,
            submittedAt: submission?.id
              ? format(new Date(submission?.updatedAt), "MM/dd/yyyy")
              : null,
            score: submission?.id
              ? `${submission?.qsetScore}/${totalQuestions}`
              : null,
            totalTimeTaken: submission?.id
              ? secondsToMinutesAndSeconds(submission?.qsetTimeTaken)
              : null,
            avgTimeTaken: submission?.id
              ? getAvgTimeTakenPerQuestion(
                  Number(submission?.qsetTimeTaken),
                  Number(totalQuestions),
                )
              : null,
            status: submission?.id
              ? submission?.isAllQuestionSubmitted
                ? "Practice Again"
                : "Continue"
              : "Try Now",
          };
        }),
      );
      const userBadges = data?.qapp?.myBadges;
      setMyBadges(
        data?.qapp?.app?.bundleAppProp?.badges?.reduce(
          (previousValue, currentValue) => {
            const index = userBadges.findIndex(
              (ub) => ub.id === currentValue.id,
            );
            previousValue.push({
              ...currentValue,
              isLocked: index === -1,
            });
            return previousValue;
          },
          [],
        ),
      );
      setLevels(data?.qapp?.app?.products);
      setValue(data?.qapp?.app?.products.length > 0
        ? data?.qapp?.app?.products[0].productName
        : "",)
      sessionStorage.setItem("appName", data?.qapp?.app?.bundleName);
      loadingCallback(false, "");
      setIsLoading(false);
    },
    onError: () => {
      loadingCallback(false, "");
    },
  });

  const [generateQSet] = useMutation(GENERATEQSET, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      setMyApp({
        ...myApp,
        qsets: [...myApp.qsets, data.generateQSet],
      });
      setLastQsetStarted(data.generateQSet);
      toast({
        position: "top",
        title: "Generated question set.",
        description: "We've generated question set for you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push(
        `/qviewer?appid=${appid}&userid=${userid}&qsetid=${data.generateQSet.id}`,
      );
    },
    onError: () => {
      loadingCallback(false, "");
      toast({
        position: "top",
        title: "Something went wrong please try again after sometime.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (user.id) {
      loadingCallback(true, "Loading app...");
      getMyApp({
        variables: {
          where: {
            id: appid,
          },
          qsetwhere: {
            AND: [
              {
                AND: [
                  {
                    QApp: {
                      id: {
                        equals: appid,
                      },
                    },
                  },
                  {
                    userId: {
                      equals: user?.id,
                    },
                  },
                ],
              },
              {
                qSetSubmissions: {
                  some: {
                    isAllQuestionSubmitted: {
                      equals: false,
                    },
                  },
                },
              },
            ],
          },
        },
      });
    }
  }, [appid, getMyApp, loadingCallback, user?.id]);

  const continuePreviousTestOrcreateQset = () => {
    if (lastQsetStarted?.id) {
      history.push(
        `/qviewer?appid=${appid}&userid=${userid}&qsetid=${lastQsetStarted?.id}`,
      );
      return true;
    }
    if (!selectedLevel) {
      toast({
        position: "top",
        title: "Please select level to generate the question set.",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
      return true;
    }
    loadingCallback(true, "generating question sets");
    generateQSet({
      variables: {
        data: {
          id: appid,
          userId: user.id,
          dynoType: "Practice",
          level: selectedLevel,
        },
      },
    });
  };

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: "level",
    onChange: (value) => setSelectedLevel(value),
  });

  const group = getRootProps();

  return {
    myApp,
    qsets,
    lastQsetStarted,
    myBadges,
    levels,
    selectedLevel,
    appid,
    userid,
    history,
    group,
    isLoading,
    getRadioProps,
    continuePreviousTestOrcreateQset,
  };
};
