import React from "react";
import {
  Box,
  Menu,
  MenuButton,
  Text,
  Flex,
  Avatar,
  Heading,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import { LoginButton } from "../LoginButton";
import { ArrowBackIcon } from "@chakra-ui/icons";

const UserMenu = ({ firstName, lastName, userPicture }) => {
  return (
    <Menu>
      <MenuButton>
        <Avatar size="md" name={`${firstName} ${lastName}`} src={userPicture} />
      </MenuButton>
    </Menu>
  );
};

export const Header = ({ isAuthenticated, user }) => {
  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");
  const history = useHistory();
  const appName = sessionStorage.getItem("appName");
  return (
    <Box
      as="header"
      sx={{
        position: "sticky",
        top: 0,
        background: "#fff",
        zIndex: 999,
        display: "block",
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="2"
        height="64px"
        borderBottom="1px solid #ccc"
        width="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="calc(100% - 64px)"
        >
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            {pathname !== "/" && (
              <Flex
                sx={{
                  alignItems: "center",
                  position: "relative",
                  "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "2px",
                    height: "100%",
                    top: "0",
                    right: "0",
                    background: "#eee",
                  },
                }}
              >
                <IconButton
                  icon={<ArrowBackIcon />}
                  onClick={() => history.goBack()}
                  variant="outline"
                  colorScheme="brand"
                />
                <Text
                  sx={{
                    margin: "0 20px 0 10px",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Back
                </Text>
              </Flex>
            )}
            <Heading
              as="h1"
              ml="20px"
              sx={{ fontSize: "24px", color: "rgb(34, 63, 153)" }}
            >
              {appName}
            </Heading>
          </Flex>
          <Button
            variant="ghost"
            colorScheme="brand"
            onClick={() =>
              history.push(`/report?appid=${appid}&userid=${userid}`)
            }
          >
            View Reports
          </Button>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          {isAuthenticated && user ? (
            <UserMenu
              firstName={user.firstName}
              lastName={user.lastName}
              userPicture={user.userPicture}
            />
          ) : (
            <LoginButton styleProps={{ variant: "outline" }} />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
