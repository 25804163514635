import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Viewer } from "../../components/Viewer";
import { useQViewer } from "../QViewerPage/useQViewer";
import { QSetHeader } from "../../components/QSetHeader";

export const QViewerReviewPage = ({ user, loadingCallback, loading }) => {
  const {
    selectedDyno,
    qSetId,
    requestStores,
    lastAttemptedDynoId,
    submissionCallback,
    dynoLoading,
    currentQSetDynoIndex,
    onChangeDyno,
    disableNext,
    disablePrev,
  } = useQViewer({ user, loadingCallback, isReview: true });
  const min = Math.floor(selectedDyno?.mySubmission?.timeTaken / 60);
  const sec = selectedDyno?.mySubmission?.timeTaken - min * 60;
  return (
    <Box>
      <QSetHeader
        onChangeDyno={onChangeDyno}
        disableNext={disableNext}
        disablePrev={disablePrev}
      />
      <Flex justifyContent="space-between" width="100%" background={"#fff"}>
        {dynoLoading && (
          <Box className="overlay-loader">
            <p>Fetching dyno...</p>
          </Box>
        )}
        <Flex width="100%" className="review-page">
          {!dynoLoading && (
            <Box
              className={
                selectedDyno.myDyno &&
                selectedDyno.myDyno.info.dyno_type === "Practice"
                  ? "practice-dyno"
                  : "common-dyno"
              }
            >
              {selectedDyno?.myDyno?._id && (
                <Viewer
                  selectedDyno={selectedDyno}
                  requestStores={requestStores}
                  userInfo={user}
                  submissionCallback={submissionCallback}
                  lastAttemptedDynoId={lastAttemptedDynoId}
                  qSetId={qSetId}
                />
              )}
            </Box>
          )}
          {!loading && !dynoLoading && (
            <Box className="info-container">
              <Box className="info-wrapper">
                <Box className="time-taken">
                  Time taken: <br />
                  <span className="timer-text">{min}</span> Min{" "}
                  <span className="timer-text">{sec}</span> Sec
                </Box>
                <Box>
                  Question no.{" "}
                  <span
                    style={{
                      fontSize: "30px",
                      color: "#00b0ff",
                      fontWeight: 600,
                    }}
                  >
                    {currentQSetDynoIndex}
                  </span>
                  /10
                </Box>
              </Box>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
