import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { QSETSUBMISSION } from "../../graphql/queries";
import { getAvgTimeTakenPerQuestion } from "../../utils/helper";

export const QSetReportPage = ({ user, userRole, loadingCallback }) => {
  const [qSetReport, setQSetReport] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const qreportid = queryParams.get("qreportid");
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");
  const history = useHistory();

  const [getQsetReport] = useLazyQuery(QSETSUBMISSION, {
    onCompleted: (data) => {
      setQSetReport({
        id: data?.qSetSubmission?.id,
        correctlyAnswered: data?.qSetSubmission?.qsetScore,
        questionsAttempted: data?.qSetSubmission?.qset?.dynosUnderQSet?.length,
        avgTimePerQuestion: getAvgTimeTakenPerQuestion(
          Number(data?.qSetSubmission?.qsetTimeTaken),
          Number(data?.qSetSubmission?.qset?.dynosUnderQSet?.length),
        ),
      });
      loadingCallback(false, "");
    },
    onError: () => {
      loadingCallback(false, "");
    },
  });

  useEffect(() => {
    loadingCallback(true, "loading report...");
    getQsetReport({
      variables: {
        where: {
          qset: {
            id: { equals: qreportid },
          },
        },
      },
    });
  }, [qreportid, getQsetReport, loadingCallback]);

  return (
    <Box padding={{ base: "20px 10px", md: "20px 20px" }}>
      <Heading
        as="h2"
        size="md"
        sx={{
          textAlign: "center",
          textTransform: "uppercase",
          margin: {
            sm: "10px 0",
            md: "20px 0",
          },
        }}
      >
        Result
      </Heading>
      <UnorderedList
        sx={{
          maxWidth: "500px",
          width: "100%",
          margin: "50px auto 0",
          lineHeight: "2",
        }}
      >
        <ListItem
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 0 10px",
          }}
        >
          <Text sx={{ display: "flex", fontWeight: "bold" }}>
            <Image
              src="./images/celebrate.png"
              alt="badge locked"
              sx={{
                width: "32px",
                margin: "0 10px 0 0",
              }}
            />{" "}
            <Box as="span">Number of questions attempted</Box>
          </Text>
          <Text>{qSetReport?.questionsAttempted}</Text>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 0 10px",
          }}
        >
          <Text sx={{ display: "flex", fontWeight: "bold" }}>
            <Image
              src="./images/correct.png"
              alt="badge locked"
              sx={{
                width: "32px",
                margin: "0 10px 0 0",
              }}
            />{" "}
            <Box as="span">Correctly answered</Box>
          </Text>
          <Text>{qSetReport?.correctlyAnswered}</Text>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text sx={{ display: "flex", fontWeight: "bold" }}>
            <Image
              src="./images/time.png"
              alt="badge locked"
              sx={{
                width: "32px",
                margin: "0 10px 0 0",
              }}
            />{" "}
            <Box as="span">Average time taken per question</Box>
          </Text>
          <Text>{qSetReport?.avgTimePerQuestion}</Text>
        </ListItem>
      </UnorderedList>
      <Flex
        sx={{
          justifyContent: "center",
          margin: "30px 0",
        }}
      >
        <Button
          colorScheme="brand"
          onClick={() =>
            history.push(
              `/qreview?appid=${appid}&userid=${userid}&qsetid=${qreportid}`,
            )
          }
        >
          {userRole === "Student" ? "View My Responses" : "View Responses"}
        </Button>
      </Flex>
    </Box>
  );
};
