import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom/";
import { DYNO, QSET, REQUESTSTORES } from "../../graphql/queries";
import { getDefaultValue } from "../../utils/helper";

export const useQViewer = ({ user, loadingCallback, isReview = false }) => {
  const [selectedDyno, setSelectedDyno] = useState({});
  const [selectedDynoId, setSelectedDynoId] = useState("");
  const [requestStores, setRequestStores] = useState({
    constants: "",
    functions: "",
    patterns: "",
    numberings: "",
    statuses: "",
    types: "",
    rubrics: "",
  });
  const [dynoIdList, setDynoIdList] = useState([]);
  const [lastAttemptedDynoId, setLastAttemptedDynoId] = useState("");
  const [qSetId, setQSetId] = useState("");
  const [dynoLoading, setDynoLoading] = useState(false);
  const [currentQSetDynoIndex, setCurrentQSetDynoIndex] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [isLastDynoOfQSet, setIsLastDynoOfQSet] = useState(false);

  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const qsetid = queryParams.get("qsetid");
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");

  const [getRequestStores] = useLazyQuery(REQUESTSTORES, {
    onCompleted: (data) => {
      setRequestStores({
        constants: { constants: data.constants },
        functions: {
          functions: data.functions.map(
            ({
              id: _id,
              name,
              example,
              space,
              description,
              arguments: params,
            }) => ({
              _id,
              name,
              example,
              space,
              description,
              arguments: params,
            }),
          ),
        },
        patterns: {
          patterns: data.patterns.map(
            ({ id: _id, patternName: name, patternCode: pattern }) => ({
              id: _id,
              name: name,
              pattern: pattern,
            }),
          ),
        },
        units: {
          units: data.numberings.map(({ id: _id, name, units }) => ({
            id: _id,
            name: name,
            units: units,
          })),
        },
        statuses: { statuses: data.dynoStatuses },
        types: { types: data.dynoTypes },
        rubrics: { rubrics: [] },
      });
    },
  });

  const [getDyno] = useLazyQuery(DYNO, {
    onCompleted: (data) => {
      const dyno = data.dyno;
      let { myDyno, myFields, myGrids, mySubmission } = getDynoData(dyno);
      setSelectedDyno({
        myDyno: myDyno,
        myFields: myFields,
        myGrids: myGrids,
        mySubmission: mySubmission,
      });
      const dynoIndex = dynoIdList.findIndex(
        (lst) => lst.id === selectedDynoId,
      );
      if (dynoIndex === 0) {
        setDisablePrev(true);
      } else {
        setDisablePrev(false);
      }
      if (dynoIndex === dynoIdList.length - 1) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }
      setCurrentQSetDynoIndex(dynoIndex + 1);
      loadingCallback(false, "");
      setDynoLoading(false);
    },
    fetchPolicy: "cache-and-network",
  });

  const [getQSet] = useLazyQuery(QSET, {
    onCompleted: (data) => {
      setDynoIdList(data.qset.dynosUnderQSet);
      if (isReview) {
        setSelectedDynoId(data.qset.dynosUnderQSet[0].id);
        setCurrentQSetDynoIndex(0 + 1);
        getSelectedDyno(data.qset.dynosUnderQSet[0].id, user.id);
        setDisablePrev(true);
        return true;
      }
      if (data.qset.qSetSubmissions.length === 0) {
        setSelectedDynoId(data.qset.dynosUnderQSet[0].id);
        setCurrentQSetDynoIndex(0 + 1);
        getSelectedDyno(data.qset.dynosUnderQSet[0].id, user.id);
        setLastAttemptedDynoId("");
        return true;
      }
      const qSetSubmissions = data.qset.qSetSubmissions[0];
      if (qSetSubmissions.isAllQuestionSubmitted) {
        setSelectedDynoId(data.qset.dynosUnderQSet[0].id);
        setCurrentQSetDynoIndex(0 + 1);
        getSelectedDyno(data.qset.dynosUnderQSet[0].id, user.id);
        setLastAttemptedDynoId("");
        return true;
      }
      const index = data.qset.dynosUnderQSet.findIndex(
        (dy) => dy.id === data.qset.qSetSubmissions[0].lastDynoAttempted.id,
      );
      setCurrentQSetDynoIndex(Number(index) + 2); //The index is for the lastDyno, so currentDyno is index+1 as index starts with 0 hence index+1+1
      setSelectedDynoId(data.qset.dynosUnderQSet[index + 1].id);
      getSelectedDyno(data.qset.dynosUnderQSet[index + 1].id, user.id);
      setLastAttemptedDynoId(data.qset.qSetSubmissions[0].lastDynoAttempted.id);
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    getRequestStores();
    loadingCallback(true, "loading dyno");
  }, [getRequestStores, loadingCallback]);

  useEffect(() => {
    const url = new URL(window.location);
    const qSet = url.searchParams.get("qsetid");
    setQSetId(qSet);
    getQSet({
      variables: {
        where: {
          id: qSet,
        },
      },
    });
  }, [getQSet]);

  const getSelectedDyno = (dynoId, userId) => {
    getDyno({
      variables: {
        where: {
          id: dynoId,
        },
        submission_where: {
          AND: [
            {
              submitter: {
                id: { equals: userId },
              },
              dyno: {
                id: { equals: dynoId },
              },
            },
          ],
        },
      },
    });
  };

  const getDynoData = (gqlDyno) => {
    let myDyno = {
      _id: gqlDyno.id,
      owner_id: gqlDyno.owner && gqlDyno.owner.id,
      owner_email: gqlDyno.owner && gqlDyno.owner.email,
      function_ids:
        gqlDyno.functions &&
        gqlDyno.functions.map(function (_ref9) {
          var id = _ref9.id;
          return id;
        }),
      updatedAt: gqlDyno.updatedAt,
      info: {
        name: gqlDyno.dynoTitle,
        description: gqlDyno.description,
        html_code: gqlDyno.dynoContent,
        category: gqlDyno.category && gqlDyno.category.id,
        dyno_type: gqlDyno.dynoType && gqlDyno.dynoType.name,
        dyno_type_id: gqlDyno.dynoType && gqlDyno.dynoType.id,
        is_public:
          gqlDyno.accessType === "ANYONEWITHLINK" ||
          gqlDyno.accessType === "PUBLIC",
        is_draft: gqlDyno.status && gqlDyno.status.statusType === "Draft",
        allow_rating: gqlDyno.allowRating,
        allow_comments: gqlDyno.allowComments,
        tags:
          gqlDyno.tags &&
          gqlDyno.tags.map(function (_ref10) {
            var id = _ref10.id;
            return id;
          }),
        locale_date: gqlDyno.localeDate ? gqlDyno.localeDate.name : null,
        locale: gqlDyno.locale ? gqlDyno.locale.name : null,
        maxPoints: gqlDyno.maxPoints,
        instructions: gqlDyno.instructions,
        dueDate: gqlDyno.dueDate,
        isTimedDyno: gqlDyno.isTimedDyno,
        timedDynoDuration: gqlDyno.timedDynoDuration,
        viewCount: gqlDyno.viewCount,
      },
      parentDyno: gqlDyno.parentDyno,
      isStudentDyno: false,
      rubric: gqlDyno.rubric,
      submission_id:
        gqlDyno.submissions.length > 0 ? gqlDyno.submissions[0].id : "",
      submission_variables: gqlDyno.submissionsVariables,
      submission_variables_db: JSON.parse(
        JSON.stringify(gqlDyno.submissionsVariables),
      ),
      lmsId: gqlDyno.lmsId,
      otherInfo: gqlDyno.otherInfo,
    };
    let myFields = [];

    if (myDyno) {
      if (gqlDyno.variables.length) {
        myFields = gqlDyno.variables.map(function (variable) {
          return {
            _id: variable.id,
            info: {
              name: variable.fieldName,
              description: variable.description,
              v_type:
                variable.variableType && variable.variableType.variableType,
              v_default: {
                value: getDefaultValue(gqlDyno.submissions[0], variable),
              },
              v_attrs: variable.variableAttributes
                ? JSON.stringify(variable.variableAttributes)
                : "{}",
              rules: variable.variableRules,
            },
            possibleAnswers: variable.possibleAnswers,
          };
        });
      }
    }
    let myGrids = [];
    if (myDyno) {
      if (gqlDyno.grids.length) {
        myGrids = gqlDyno.grids;
      }
    }
    let mySubmission = gqlDyno.submissions[0] || {};
    return { myDyno, myFields, myGrids, mySubmission };
  };

  const submissionCallback = (submission) => {
    setLastAttemptedDynoId(submission.data.upsertQSetDynoSubmission.dyno.id);
    const dynoIndex = dynoIdList.findIndex((lst) => lst.id === selectedDynoId);
    if (dynoIndex + 1 === dynoIdList.length) {
      history.push(
        `/qreport?appid=${appid}&userid=${userid}&qreportid=${qsetid}`,
      );
    } else {
      setDynoLoading(true);
      setSelectedDynoId(dynoIdList[dynoIndex + 1].id);
      getSelectedDyno(dynoIdList[dynoIndex + 1].id, user.id);
    }
  };

  const onChangeDyno = (isNext) => {
    const dynoIndex = dynoIdList.findIndex((lst) => lst.id === selectedDynoId);
    if (isNext) {
      if (dynoIndex + 1 === dynoIdList.length) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
        setDynoLoading(true);
        setSelectedDynoId(dynoIdList[dynoIndex + 1].id);
        getSelectedDyno(dynoIdList[dynoIndex + 1].id, user.id);
      }
    } else {
      if (dynoIndex - 1 === -1) {
        setDisablePrev(true);
      } else {
        setDisablePrev(false);
        setDynoLoading(true);
        setSelectedDynoId(dynoIdList[dynoIndex - 1].id);
        getSelectedDyno(dynoIdList[dynoIndex - 1].id, user.id);
      }
    }
  };

  return {
    selectedDyno,
    qSetId,
    requestStores,
    lastAttemptedDynoId,
    submissionCallback,
    dynoLoading,
    currentQSetDynoIndex,
    onChangeDyno,
    disableNext,
    disablePrev,
    isLastDynoOfQSet,
  };
};
