import React, { useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormInput } from "../FormInput";
import { FormPassword } from "../FormPassword";

export const NewPassword = ({
  firstName,
  familyName,
  newPassword,
  newPasswordAgain,
  isPasswordError,
  isRepeatPasswordError,
  onNameChange,
  onPasswordChange,
  onNewPasswordSubmit,
  showPassword,
  toggleShowPassword,
}) => {
  const [userNewPassword, setUserNewPassword] = useState(newPassword);
  const [userRepeatPassword, setUserRepeatPassword] =
    useState(newPasswordAgain);
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Enter new password
        </Heading>
        <form onSubmit={onNewPasswordSubmit}>
          <FormInput
            label="Enter First Name"
            name="firstName"
            type="text"
            placeholder="Enter First Name"
            inputOnChange={onNameChange}
            value={firstName}
            formProps={{ isRequired: true }}
            padding="0 0 20px"
          />
          <FormInput
            label="Enter Family Name"
            name="familyName"
            type="text"
            placeholder="Enter Family Name"
            inputOnChange={onNameChange}
            value={familyName}
            formProps={{ isRequired: true }}
            padding="0 0 20px"
          />
          <FormPassword
            label="New Password"
            name="new_password"
            showPassword={showPassword}
            toggleShowPassword={toggleShowPassword}
            placeholder=""
            inputOnChange={(e) => {
              onPasswordChange(e);
              setUserNewPassword(e.target.value);
            }}
            value={userNewPassword}
            formProps={{ isRequired: true, isInvalid: isPasswordError }}
            padding="0 0 20px"
            errorMessage={
              <>
                <Text
                  color={/[a-z]/.test(newPassword) ? "green.500" : "red.500"}
                >
                  Password must contain a lower case letter.
                </Text>
                <Text
                  color={/[A-Z]/.test(newPassword) ? "green.500" : "red.500"}
                >
                  Password must contain an upper case letter.
                </Text>
                <Text
                  color={
                    /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(newPassword)
                      ? "green.500"
                      : "red.500"
                  }
                >
                  Password must contain a special character.
                </Text>
                <Text color={/\d/.test(newPassword) ? "green.500" : "red.500"}>
                  Password must contain a number.
                </Text>
                <Text color={newPassword.length >= 8 ? "green.500" : "red.500"}>
                  Password must contain at least 8 characters.
                </Text>
              </>
            }
          />
          <FormPassword
            label="Enter New Password Again"
            name="new_password_again"
            showPassword={showPassword}
            toggleShowPassword={toggleShowPassword}
            placeholder=""
            inputOnChange={(e) => {
              onPasswordChange(e);
              setUserRepeatPassword(e.target.value);
            }}
            value={userRepeatPassword}
            formProps={{ isRequired: true, isInvalid: isRepeatPasswordError }}
            padding="0 0 20px"
            errorMessage={"Your password should match with the above one."}
          />
          <Stack spacing={6}>
            <Button
              colorScheme="brand"
              _hover={{
                bg: "blue.500",
              }}
              type="submit"
            >
              Create Password
            </Button>
          </Stack>
        </form>
      </Stack>
    </Flex>
  );
};
