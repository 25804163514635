import { jwtDecode } from "jwt-decode";
import { getCookie } from "./cookie";

const getQueryParams = (params, url) => {
  let href = url;
  //this expression is to get the query strings
  let reg = new RegExp(`[?&]${params}=([^&#]*)`, "i");
  let queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

const getUser = () => {
  const token = getCookie("dyn_session");
  return jwtDecode(token);
};

const range = (start, end) => {
  let length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

const getDynoFieldsArguments = (node) => ({
  id: node.node.id,
  dynoTitle: node.node.dynoTitle,
  description: node.node.description,
  dynoPublishingStatus: node.node.dynoPublishingStatus,
  accessType: node.node.accessType,
  createdAt: node.node.createdAt,
  updatedAt: node.node.updatedAt,
  owner: node.node.owner,
  dynoType: node.node.dynoType,
  category: node.node.category,
});

const getUniqueDynoes = (dynoes, dynoesRes) => {
  const updatedDynoes = [...dynoes];
  if (dynoes.length === 0) return dynoesRes;
  for (const dynoe of dynoesRes) {
    let isDynoMatched = false;
    let dynoIndex = -1;
    for (const [index, dyno] of dynoes.entries()) {
      if (dyno.id === dynoe.id) {
        isDynoMatched = true;
        dynoIndex = index;
      }
    }
    if (isDynoMatched) {
      updatedDynoes[dynoIndex] = dynoe;
    } else {
      updatedDynoes.push(dynoe);
    }
  }
  return updatedDynoes;
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
  return re.test(String(password));
};

const validatePhone = (phone) => {
  const re = /^\d{10}$/;
  return re.test(Number(phone));
};

const makeFirstLetterCapital = (word) => {
  return word
    .split("")
    .map((letter, index) => (index === 0 ? letter.toUpperCase() : letter))
    .join("");
};

const validateImage = (file, maxwidth, maxheight) => {
  let _URL = window.URL || window.webkitURL;
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject("Image size must be " + maxwidth + " X " + maxheight);
    };
  });
};

const validateVimeoURL = (url) => {
  return /^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/.test(
    url,
  );
};

const secondsToMinutesAndSeconds = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes}m:${seconds}sec`;
};

const getAvgTimeTakenPerQuestion = (totalSeconds, totalQuestions) => {
  let averageTimePerQuestionInSeconds = Math.floor(
    totalSeconds / totalQuestions,
  );
  let remainingSeconds = Math.floor(totalSeconds % 60);
  let remainingAvgTimePerQuestionInSeconds = Math.round(
    remainingSeconds / totalQuestions,
  );
  return secondsToMinutesAndSeconds(
    averageTimePerQuestionInSeconds + remainingAvgTimePerQuestionInSeconds,
  );
};
const getDefaultValue = (submission, variable) => {
  const {
    id,
    defaultValue,
    variableType,
    variableAttributes: {},
  } = variable;
  if (!submission) return defaultValue;
  if (!submission.quizAnswers) return defaultValue;
  const answer = submission.quizAnswers.find((x) => x.variable.id === id);
  if (!answer) return defaultValue;
  const vType = variableType.variableType;
  const aType = typeof answer.value;
  const varIsChecklist = vType === "Checklist";
  const valueIsStr = aType === "string";
  if (varIsChecklist && valueIsStr) {
    return answer.value;
  }
  return answer.value || defaultValue || "";
};

export {
  getQueryParams,
  getUser,
  range,
  getDynoFieldsArguments,
  getUniqueDynoes,
  validateEmail,
  validatePassword,
  validatePhone,
  makeFirstLetterCapital,
  validateImage,
  validateVimeoURL,
  getDefaultValue,
  getAvgTimeTakenPerQuestion,
  secondsToMinutesAndSeconds,
};
