import React from "react";
import { Box } from "@chakra-ui/react";
import { Header } from "../Header";

export const PageLayout = ({
  children,
  loading,
  user,
  appName,
  bgColor = "#fff",
}) => {
  return (
    <Box
      position={loading ? "fixed" : "unset"}
      width="100%"
      height={loading ? "100vh" : "auto"}
    >
      <Header isAuthenticated={true} user={user} showMenuButton={false} />
      <Box as="main" bgColor={bgColor}>
        {children}
      </Box>
    </Box>
  );
};
