import React, { useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Box,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useColorModeValue,
  InputGroup,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { FormPassword } from "../FormPassword";
import { CountryCodeSelect } from "../CountryCodeSelect";

export const SignIn = ({
  username,
  password,
  errorMessage,
  signinErrorMessage,
  isSigninError,
  showCountryCode,
  isError,
  showPassword,
  onUserNameChange,
  onPasswordChange,
  toggleShowPassword,
  signIn,
  codeChange,
}) => {
  const [userPassword, setUserPassword] = useState(password);
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <form onSubmit={signIn}>
            <Stack>
              <FormControl
                padding="0 0 10px"
                colorScheme="brand"
                isRequired={true}
                isInvalid={isError}
              >
                {isSigninError && (
                  <Text color="red.600" textAlign="center" padding="0 0 10px">
                    {signinErrorMessage}
                  </Text>
                )}
                <FormLabel>Email or Phone number or Username</FormLabel>
                <InputGroup>
                  {showCountryCode && (
                    <CountryCodeSelect codeChange={codeChange} />
                  )}
                  <Input
                    type="text"
                    name="username"
                    placeholder="Email or Phone number or Username"
                    value={username}
                    errorBorderColor="red.300"
                    onChange={onUserNameChange}
                  />
                </InputGroup>
                <FormErrorMessage display="block">
                  {errorMessage}
                </FormErrorMessage>
              </FormControl>
              <FormPassword
                label="Password"
                name="password"
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
                placeholder="password"
                inputOnChange={(e) => {
                  onPasswordChange(e);
                  setUserPassword(e.target.value);
                }}
                value={userPassword}
                formProps={{ isRequired: true }}
                padding="0 0 10px"
              />
              <Stack margin="0">
                <Button
                  colorScheme="brand"
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  Sign in
                </Button>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align="start"
                  justify="center"
                  padding="10px 0 0"
                >
                  <NavLink to={`/forgetpassword`}>
                    <Text color="brand.600">Forgot password?</Text>
                  </NavLink>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
