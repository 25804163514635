import React from "react";
import { Switch, Route } from "react-router-dom";
import { HomePage } from "../HomePage";
import { PageLayout } from "../../components/PageLayout";
import { PreviousTestsPage } from "../PreviousTestsPage";
import { QViewerPage } from "../QViewerPage";
import { QViewerReviewPage } from "../QViewerReviewPage";
import { QSetReportPage } from "../QSetReportPage";
import { ReportPage } from "../ReportPage";

export const MainPage = ({ user, userRole, loadingCallback, loading }) => {
  return (
    <PageLayout loading={loading} user={user} userRole={userRole}>
      <Switch>
        <Route exact path="/">
          <HomePage
            user={user}
            userRole={userRole}
            loadingCallback={loadingCallback}
          />
        </Route>
        <Route exact path="/previoustests">
          <PreviousTestsPage
            user={user}
            userRole={userRole}
            loadingCallback={loadingCallback}
          />
        </Route>
        <Route exact path="/qreport">
          <QSetReportPage
            user={user}
            userRole={userRole}
            loadingCallback={loadingCallback}
          />
        </Route>
        <Route exact path="/report">
          <ReportPage
            user={user}
            userRole={userRole}
            loadingCallback={loadingCallback}
          />
        </Route>
        <Route exact path="/qviewer">
          <QViewerPage
            user={user}
            userRole={userRole}
            loadingCallback={loadingCallback}
            loading={loading}
          />
        </Route>
        <Route exact path="/qreview">
          <QViewerReviewPage
            user={user}
            userRole={userRole}
            loadingCallback={loadingCallback}
            loading={loading}
          />
        </Route>
      </Switch>
    </PageLayout>
  );
};
