import React, { useState, useEffect, useCallback } from "react";
// import { ErrorBoundary } from "react-error-boundary";
import { Auth } from "aws-amplify";
import { ChakraProvider, Box, Text, Spinner } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { SignInPage } from "./pages/SignInPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { useLogin } from "./hooks/useLogin";
import { MainPage } from "./pages/MainPage";
import { ErrorView } from "./components/ErrorView";
import theme from "./theme";
import "./App.scss";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoggedin, user, userRole, loggedInUser] = useLogin(Auth);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  // const { search } = useLocation();
  // const history = useHistory();
  // const queryParams = new URLSearchParams(search);
  // const appid = queryParams.get('appid');

  const loadingCallback = useCallback((isLoading, msg) => {
    setLoading(isLoading);
    setLoadingMsg(msg);
  }, []);

  useEffect(() => {
    if (isLoggedin) {
      setIsAuthenticated(true);
    }
  }, [isLoggedin, loggedInUser]);

  return (
    <ChakraProvider theme={theme}>
      {/* <ErrorBoundary fallback={<ErrorView appid={appid} />}> */}
      <Router>
        {isAuthenticated ? (
          <MainPage
            user={user}
            userRole={userRole}
            loading={loading}
            loadingCallback={loadingCallback}
            Auth={Auth}
          />
        ) : (
          <Switch>
            <Route exact path="/">
              <SignInPage loadingCallback={loadingCallback} />
            </Route>
            <Route path="/login">
              <SignInPage loadingCallback={loadingCallback} />
            </Route>
            <Route path="/forgetpassword">
              <ForgotPasswordPage loadingCallback={loadingCallback} />
            </Route>
          </Switch>
        )}
        {loading && (
          <Box
            w="100%"
            h="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="fixed"
            top="0"
            zIndex="9999"
            left="0"
            bg="rgba(238, 238, 238, 0.5)"
          >
            <Box sx={{ textAlign: "center" }}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
              <Text>{loadingMsg}</Text>
            </Box>
          </Box>
        )}
      </Router>
      {/* </ErrorBoundary> */}
    </ChakraProvider>
  );
}

export default App;
