import React, { useState } from "react";
import { Button, Flex, Stack, useColorModeValue } from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { useHistory, NavLink } from "react-router-dom";
import {
  validateEmail,
  validatePhone,
  validatePassword,
} from "../../utils/helper";
import { ForgotPassword } from "../../components/ForgotPassword";
import { ChangePassword } from "../../components/ChangePassword";

export const ForgotPasswordPage = ({ loadingCallback }) => {
  const [username, setUsername] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [isCodeError, setIsCodeError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false);
  const [isForgotPasswordScreen, setIsForgotPasswordScreen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] =
    useState("");
  const history = useHistory();
  const onUsernameChange = (e) => {
    setUsername(e.target.value);
    if (isNaN(e.target.value) || e.target.value === "") {
      setShowCountryCode(false);
    } else {
      setShowCountryCode(true);
    }
    if (isNaN(e.target.value) && !validateEmail(e.target.value)) {
      setIsError(true);
      setErrorMessage("Enter a valid email");
    } else if (!isNaN(e.target.value) && !validatePhone(e.target.value)) {
      setIsError(true);
      setErrorMessage("Enter a valid phone number");
    } else {
      setIsError(false);
      setErrorMessage("");
    }
  };
  const onPasswordChange = (e) => {
    if (e.target.name === "new_password") {
      setNewPassword(e.target.value);
      if (!validatePassword(e.target.value)) {
        setIsPasswordError(true);
      } else {
        setIsPasswordError(false);
      }
    }
    if (e.target.name === "new_password_again") {
      setNewPasswordAgain(e.target.value);
      if (
        !validatePassword(e.target.value) ||
        newPassword.trim() !== e.target.value
      ) {
        setIsRepeatPasswordError(true);
      } else {
        setIsRepeatPasswordError(false);
      }
    }
  };
  const onResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(newPassword)) {
      setIsPasswordError(true);
      return true;
    }
    if (
      !validatePassword(newPasswordAgain) ||
      newPassword !== newPasswordAgain
    ) {
      setIsRepeatPasswordError(true);
      return true;
    }
    try {
      loadingCallback(true, "changing your password...");
      const userName = isNaN(username) ? username : `${countryCode}${username}`;
      await Auth.forgotPasswordSubmit(userName, code, newPassword);
      loadingCallback(false, "");
      history.push("/login");
    } catch (err) {
      loadingCallback(false, "");
      setIsCodeError(true);
      setChangePasswordErrorMessage(err.message);
    }
  };
  const onForgotPasswordSubmit = async (e) => {
    if (e) e.preventDefault();
    if (isNaN(username) && !validateEmail(username)) {
      setIsError(true);
      setErrorMessage("Enter a valid email");
      return true;
    }
    if (!isNaN(username) && !validatePhone(username)) {
      setIsError(true);
      setErrorMessage("Enter a valid phone number");
      return true;
    }
    try {
      const userName = isNaN(username) ? username : `${countryCode}${username}`;
      loadingCallback(true, "sending the code...");
      await Auth.forgotPassword(userName);
      loadingCallback(false, "");
      setIsForgotPasswordScreen(false);
    } catch (err) {
      loadingCallback(false, "");
    }
  };
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        {isForgotPasswordScreen ? (
          <ForgotPassword
            username={username}
            errorMessage={errorMessage}
            showCountryCode={showCountryCode}
            isError={isError}
            onUsernameChange={onUsernameChange}
            onForgotPasswordSubmit={onForgotPasswordSubmit}
            codeChange={(country_code) => setCountryCode(country_code)}
          />
        ) : (
          <ChangePassword
            code={code}
            newPassword={newPassword}
            newPasswordAgain={newPasswordAgain}
            changePasswordErrorMessage={changePasswordErrorMessage}
            isCodeError={isCodeError}
            isPasswordError={isPasswordError}
            isRepeatPasswordError={isRepeatPasswordError}
            onPasswordChange={onPasswordChange}
            onCodeChange={(e) => setCode(e.target.value)}
            onResetPasswordSubmit={onResetPasswordSubmit}
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(!showPassword)}
          />
        )}
        {isCodeError && (
          <Button
            colorScheme="brand"
            variant="ghost"
            onClick={() => {
              setIsCodeError(false);
              onForgotPasswordSubmit();
            }}
          >
            Resend code again
          </Button>
        )}
        <Button colorScheme="brand" variant="ghost">
          <NavLink to={`/login`}>Back to login</NavLink>
        </Button>
      </Stack>
    </Flex>
  );
};
