import React from "react";
import { Box, Image } from "@chakra-ui/react";

export const BadgeImage = ({ src, alt, isLocked }) => {
  return (
    <Box
      sx={{
        width: "32px",
      }}
    >
      {!isLocked && (
        <Image
          src={src}
          alt={alt}
          sx={{
            width: "32px",
          }}
        />
      )}
      {isLocked && (
        <Image
          src="./images/lock.png"
          alt="badge locked"
          sx={{
            width: "32px",
          }}
        />
      )}
    </Box>
  );
};
