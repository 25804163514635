export const addressFields = `
    id
    street1
    street2
    city
    state
    zipcode
`;

export const organizationFields = `
    id
    email
    name
    domain
    adminEmails
    organizationType {
        id
        name
    }
    standards {
        id
        name
    }
    users {
        id
        firstName
        lastName
        email
        classes {
            id
            name
            subscribers {
                email
            }
        }
    }
    address {
        ${addressFields}
    }
    billing_address {
        ${addressFields}
    }
    isLMSIntegrationNeeded
    lMSIntegrated {
        id
        url
        deploymentId
        clientId
        ltiaasPlatformId
        authenticationEndpoint
        accesstokenEndpoint
        jwksURL
    }
`;

export const userFields = `
    id
    firstName
    lastName
    email
    phone
    userAccountType {
      userAccountType
      id
    }
    userRole {
      id
      role
    }
    organizations {
        id
        name
        email
        logoURL
    }
    loginCount
    aboutMe
    otherInfo
`;

export const myDynosFields = `
    id
    dynoTitle
    description
    accessType
    thumbnailUrl
    createdAt
    updatedAt
    allowCopy
    maxPoints
    viewCount
    grades {
        id
        name
    }
    subjects {
        id
        name
    }
    standards {
        id
        name
    }
    owner {
        id
        firstName
        lastName
    }
    dynoPublishingStatus
    dynoType {
        id
        name
        description
    }
    tags {
        id
        name
    }
    standardsId {
        id
        name
        grade
        subject
        standard {
            id
            name
        }
        asn {
            description
        }
    }
    category {
        id
        name
        parentCategory {
            id name
            parentCategory {
            id name
            }
        }
        }
        parentDyno {
        id
        dynoTitle
        owner {
            id
            email
            phone
            firstName
            lastName
        }
    }
    variables{
        id
        fieldName
        title
        description
        defaultValue
        variableAttributes
        variableRules {
            id
        }
        variableType {
            id
            variableType
        }
        possibleAnswers {
            id
            value
            explanation
            maxPoints
        }        
    }
    grids {
        id
        title
        label
        backgroundColor
        showGrid
        snapToGrid
        showAllProperties
        showGridOrigin
        showGridSize
        width
        height
        attributes
        origin
        shapes{
          id
          title
          label
          color
          type
          isPresent
          isMovementLocked
          position
          attributes
          viewerControls
          grid {
            id
            title
          }
        }
        groups{
          title
          label
          shapes{
              id
          }
        }
      }
    submissionsVariables {
        id
        fieldName
        title
        description
        defaultValue
        variableAttributes
        variableRules {
            id
        }
        variableType {
            id
            variableType
        }
        possibleAnswers {
            id
            value
            explanation
            maxPoints
        }
    }
    review {
        isLocked
    }
    dynoContent
    otherInfo
`;

export const submissionsVariables = `
      id
      reviewedAt
      submittedAt
      isLocked
      totalPoints
      updatedAt
      timeTaken
      submitter {
        id
        email
        phone
      }
      organizationClass {
        id
        organizations {
          id
          logoURL
          __typename
        }
        __typename
      }
      dyno {
        lmsId
      }
      quizAnswers {
        id
        value
        teacherComment
        points
        variable {
          id
          fieldName
          title
          variableAttributes
          variableType {
            id
            variableType
          }
          possibleAnswers {
            id
            value
            maxPoints
            explanation
          }
        }
        grid {
          id
          title
          label
          backgroundColor
          showGrid
          snapToGrid
          showAllProperties
          showGridOrigin
          showGridSize
          width
          height
          attributes
          origin
            shapes{
            id
            title
            label
            color
            type
            isPresent
            isMovementLocked
            position
            attributes
            viewerControls
            grid {
              id
              title
            }
          }
            groups{
            title
            label
            shapes{
              id
            }
          }
        }
      }
`;
