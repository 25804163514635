import React from "react";
import {
  Box,
  Text,
  Heading,
  Button,
  Flex,
  Grid,
  List,
  ListItem,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Card } from "../../components/Card";
import { BadgeImage } from "../../components/BadgeImage";
import { PracticeIcon } from "../../components/Icons/PracticeIcon";
import { BadgesIcon } from "../../components/Icons/BadgesIcon";
import { useHome } from "./useHome";
import { LevelCard } from "../../components/LevelCard";

export const HomePage = ({ user, userRole, loadingCallback }) => {
  const {
    myApp,
    qsets,
    lastQsetStarted,
    myBadges,
    levels,
    appid,
    userid,
    history,
    group,
    isLoading,
    getRadioProps,
    continuePreviousTestOrcreateQset,
  } = useHome({ user, loadingCallback });

  return (
    <Box padding={{ base: "20px 10px", md: "20px 20px" }}>
      <Text>{myApp?.app?.bundleDescription}</Text>
      {userRole === "Student" && (
        <Box
          sx={{
            margin: "30px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isLoading && (
            <Flex
              {...group}
              sx={{
                margin: "0 0 20px",
                display: "flex",
                flexDirection: { sm: "column", md: "row" },
              }}
            >
              {React.Children.toArray(
                levels.map((level, index) => {
                  const radio = getRadioProps({
                    value: level.productName,
                    isDisabled: !myApp?.levelStatus[index],
                    ref: myApp?.app?.bundleAppProp?.preReqs[index],
                  });
                  return (
                    <LevelCard {...radio} criteria={level.criteria}>
                      {level.productName}
                    </LevelCard>
                  );
                }),
              )}
            </Flex>
          )}
          <Button
            colorScheme="brand"
            onClick={continuePreviousTestOrcreateQset}
          >
            {lastQsetStarted?.id
              ? "Continue previous test"
              : "Generate question set"}
          </Button>
        </Box>
      )}
      <Grid
        sx={{
          gridTemplateColumns: {
            sm: "repeat(1, 1fr)",
            md: "repeat(1, 1fr)",
          },
          gap: {
            sm: "4",
            md: "6",
          },
          margin: "30px auto 0",
        }}
      >
        <StoryCard
          title="Practice Tests"
          icon={<PracticeIcon />}
          body={
            <Box overflowX="auto">
              <LastThreeQsets
                userRole={userRole}
                qsets={qsets}
                practiceQset={(id) =>
                  history.push(
                    `/qviewer?appid=${appid}&userid=${userid}&qsetid=${id}`,
                  )
                }
              />
              {qsets?.length === 0 && <Text>No prvious tests to show!</Text>}
            </Box>
          }
          link={() =>
            history.push(`/previoustests?appid=${appid}&userid=${userid}`)
          }
        />
        <StoryCard
          title="Badges"
          icon={<BadgesIcon />}
          body={
            <Box sx={{ padding: "1.25rem" }}>
              <List>
                {React.Children.toArray(
                  myBadges?.map((badge) => (
                    <ListItem>
                      <Flex
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "10px 0",
                        }}
                      >
                        <BadgeImage
                          src={badge?.badgeImage}
                          alt={badge.badgeName}
                          isLocked={badge.isLocked}
                        />
                        <Text sx={{ fontWeight: "bold" }}>
                          {badge.badgeName}
                        </Text>
                        <Text sx={{ width: "50%", fontSize: "md" }}>
                          {badge.isLocked
                            ? `Unlock this badge when ${badge.criteria} questions answered correctly.`
                            : `Congratulations! 🎉 You've unlocked the ${badge.badgeName} badge! Keep up the great work and continue your journey to excellence!`}
                        </Text>
                      </Flex>
                    </ListItem>
                  )),
                )}
              </List>
            </Box>
          }
          link=""
        />
      </Grid>
    </Box>
  );
};

const StoryCard = ({ title, body, link, icon }) => {
  return (
    <Card
      header={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "solid 1px rgba(34, 63, 153, 0.1)",
            padding: "1rem",
            svg: {
              width: "32px",
              height: "32px",
            },
          }}
        >
          <Heading size="md" sx={{ display: "flex", alignItems: "center" }}>
            {icon}
            <Box as="span">{title}</Box>
          </Heading>
          {!!link && (
            <IconButton
              variant="ghost"
              colorScheme="brand"
              icon={<ArrowForwardIcon />}
              fontSize="1.5rem"
              onClick={link}
            />
          )}
        </Box>
      }
      body={body}
    />
  );
};

const LastThreeQsets = ({ qsets, practiceQset, userRole }) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Date Of Test</Th>
          <Th>Score</Th>
          <Th>Total Time Taken</Th>
          <Th>Avg Time Per Question</Th>
          {userRole === "Student" && <Th>&nbsp;</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {React.Children.toArray(
          qsets.map((qset) => (
            <Tr>
              <Td>{qset.name}</Td>
              <Td>{qset.submittedAt}</Td>
              <Td>{qset.score}</Td>
              <Td>{qset.totalTimeTaken}</Td>
              <Td>{qset.avgTimeTaken}</Td>
              {userRole === "Student" && (
                <Td>
                  <Button
                    variant="ghost"
                    colorScheme="brand"
                    aria-label="practice"
                    onClick={() => practiceQset(qset.id)}
                    rightIcon={<ArrowForwardIcon />}
                  >
                    {qset.status}
                  </Button>
                </Td>
              )}
            </Tr>
          )),
        )}
      </Tbody>
    </Table>
  );
};
