import {
  userpoolId,
  userpoolClientId,
  cognitoRegionName,
  appDomain,
} from "../config";

export const awsconfig = {
  region: cognitoRegionName,
  userPoolId: userpoolId,
  userPoolWebClientId: userpoolClientId,
  cookieStorage: {
    domain: appDomain,
    path: "/",
    expires: 365,
    secure: true,
  },
};
