import React from "react";
import { Box } from "@chakra-ui/react";

export const Card = ({ header, body }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        overflowWrap: "break-word",
        background: "#ff",
        backgroundColor: "var(--card-bg)",
        boxShadow:
          "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        borderRadius: "0.375rem",
        color: "#1A202C",
        borderWidth: "0",
        borderColor: "none",
      }}
    >
      <>{header}</>
      <>{body}</>
    </Box>
  );
};
