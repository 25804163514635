import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";

export const Timer = () => {
  const [timeSpentOnQSetDyno, setTimeSpentOnQSetDyno] = useState(0);
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const hours =
        Math.floor((timeSpentOnQSetDyno + 1) / 3600) >= 10
          ? Math.floor((timeSpentOnQSetDyno + 1) / 3600)
          : `0${Math.floor((timeSpentOnQSetDyno + 1) / 3600)}`;
      const minutes =
        Math.floor(((timeSpentOnQSetDyno + 1) / 60) % 60) >= 10
          ? Math.floor(((timeSpentOnQSetDyno + 1) / 60) % 60)
          : `0${Math.floor(((timeSpentOnQSetDyno + 1) / 60) % 60)}`;
      const seconds =
        Math.floor((timeSpentOnQSetDyno + 1) % 60) >= 10
          ? Math.floor((timeSpentOnQSetDyno + 1) % 60)
          : `0${Math.floor((timeSpentOnQSetDyno + 1) % 60)}`;
      setTimer({ hours, minutes, seconds });
      setTimeSpentOnQSetDyno((prevTime) => prevTime + 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [timeSpentOnQSetDyno]);

  return (
    <Box className="timer-container">
      <Text className="timer-text">{`${timer.hours}:${timer.minutes}:${timer.seconds}`}</Text>
    </Box>
  );
};
