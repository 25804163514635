import React, { useState } from "react";
import { Select, FormControl } from "@chakra-ui/react";
import { country_codes } from "../../utils/countryCodes";

export const CountryCodeSelect = ({ codeChange }) => {
  const [value, setValue] = useState();
  const onChange = (e) => {
    setValue(e.target.value);
    codeChange(e.target.value.split(" ")[0]);
  };
  return (
    <FormControl width="165px" colorScheme="brand" isRequired={true}>
      <Select value={value} onChange={onChange} name="Country code" padding="0">
        {country_codes.map((country) => (
          <option key={country.dial_code} value={country.dial_code}>
            {country.dial_code} {country.code}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
