import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export const FormPassword = ({
  label,
  showPassword = false,
  placeholder,
  formProps,
  value,
  inputOnChange,
  name,
  padding = { base: "0 10px 10px 0", md: "0 20px 20px 0" },
  errorMessage = "",
  toggleShowPassword,
}) => {
  return (
    <FormControl padding={padding} colorScheme="brand" {...formProps}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Input
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          value={value || ""}
          onChange={inputOnChange}
          errorBorderColor="red.300"
          name={name}
        />
        <InputRightElement h={"full"}>
          <Button variant={"ghost"} onClick={toggleShowPassword}>
            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage display="block">{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
