import React from "react";
import { Box } from "@chakra-ui/react";

export const PracticeIcon = ({ styles }) => {
  return (
    <Box sx={{ styles }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width="64px"
        height="64px"
      >
        <rect width="50" height="56" x="7" y="5" fill="#cda1a7" rx="2" ry="2" />
        <rect
          width="2"
          height="48"
          x="31"
          y="-17"
          fill="#deb4ba"
          rx="1"
          ry="1"
          transform="rotate(-90 32 7)"
        />
        <path fill="#faefde" d="M13 55L43 55 51 47 51 11 13 11 13 55z" />
        <path fill="#bbdef9" d="M43 47L51 47 43 55 43 47z" />
        <path
          fill="#fff7f0"
          d="M51 35.47L51 14.63 17.57 54.88 33.88 54.88 51 35.47zM13.4 53.4L48.5 11 44 11 12.5 49.6 13.4 53.4zM13 43.42L39.5 11 38 11 13 41.53 13 43.42z"
        />
        <path
          fill="#fff"
          d="M45.5 29L46.74 31.26 49 32.5 46.74 33.74 45.5 36 44.26 33.74 42 32.5 44.26 31.26 45.5 29z"
        />
        <path fill="#f9e3ae" d="M42 6L32 1 22 6 21 13 43 13 42 6z" />
        <path
          fill="#fff"
          d="M25.42 21L26.27 22.56 27.84 23.42 26.27 24.27 25.42 25.84 24.56 24.27 23 23.42 24.56 22.56 25.42 21zM22.65 48L23.23 49.06 24.3 49.65 23.23 50.23 22.65 51.3 22.07 50.23 21 49.65 22.07 49.06 22.65 48z"
        />
        <path fill="#8d6c9f" d="M32 4A2 2 0 1 0 32 8A2 2 0 1 0 32 4Z" />
        <path
          fill="#8d6c9f"
          d="M55,4H40.58l-2.7-2A10,10,0,0,0,26.12,2l-2.7,2H9A3,3,0,0,0,6,7V59a3,3,0,0,0,3,3H55a3,3,0,0,0,3-3V7A3,3,0,0,0,55,4ZM27.29,3.66a8,8,0,0,1,9.42,0l.47.34,2.74,2,.84.61A3,3,0,0,1,42,9v2a1,1,0,0,1-1,1H23a1,1,0,0,1-1-1V9a3,3,0,0,1,1.24-2.43L24.08,6l2.74-2ZM50,46H44a2,2,0,0,0-2,2v6H14V12h6.18A3,3,0,0,0,23,14H41a3,3,0,0,0,2.82-2H50Zm-1.41,2L44,52.59V48ZM56,59a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V7A1,1,0,0,1,9,6H21a5,5,0,0,0-1,3v1H14a2,2,0,0,0-2,2V54a2,2,0,0,0,2,2H42.17a3,3,0,0,0,2.12-.88l6.83-6.83A3,3,0,0,0,52,46.17V12a2,2,0,0,0-2-2H44V9a5,5,0,0,0-1-3H55a1,1,0,0,1,1,1Z"
        />
        <path
          fill="#8d6c9f"
          d="M17 16a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V17A1 1 0 0 0 17 16zM22 20a1 1 0 0 0 1-1V17a1 1 0 0 0-2 0v2A1 1 0 0 0 22 20zM27 20a1 1 0 0 0 1-1V17a1 1 0 0 0-2 0v2A1 1 0 0 0 27 20zM32 20a1 1 0 0 0 1-1V17a1 1 0 0 0-2 0v2A1 1 0 0 0 32 20zM38 17a1 1 0 0 0-2 0v2a1 1 0 0 0 2 0zM42 20a1 1 0 0 0 1-1V17a1 1 0 0 0-2 0v2A1 1 0 0 0 42 20zM47 20a1 1 0 0 0 1-1V17a1 1 0 0 0-2 0v2A1 1 0 0 0 47 20zM32 9a3 3 0 1 0-3-3A3 3 0 0 0 32 9zm0-4a1 1 0 1 1-1 1A1 1 0 0 1 32 5z"
        />
        <path
          fill="#72caaf"
          d="M20 30h6a1 1 0 0 0 0-2H20a1 1 0 0 0 0 2zM34 28H30a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z"
        />
        <path fill="#ed7899" d="M34,38H20a1,1,0,0,0,0,2H34a1,1,0,0,0,0-2Z" />
        <path
          fill="#72caaf"
          d="M39.71,28.29a1,1,0,0,0-1.41,1.41l2,2a1,1,0,0,0,1.41,0l4-4a1,1,0,0,0-1.41-1.41L41,29.59Z"
        />
        <path
          fill="#ed7899"
          d="M44.71,36.29a1,1,0,0,0-1.41,0L42,37.59l-1.29-1.29a1,1,0,0,0-1.41,1.41L40.59,39l-1.29,1.29a1,1,0,1,0,1.41,1.41L42,40.41l1.29,1.29a1,1,0,0,0,1.41-1.41L43.41,39l1.29-1.29A1,1,0,0,0,44.71,36.29Z"
        />
      </svg>
    </Box>
  );
};
