export const country_codes = [
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    flag: "", // require("../countryFlags/india.png"),
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
    flag: "", // require("../countryFlags/bangladesh.png"),
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    flag: "", // require("../countryFlags/sri-lanka.png"),
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    flag: "", // require("../countryFlags/united-arab-emirates.png"),
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
    flag: "", // require("../countryFlags/usa.png"),
  },
];
