import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { NewPassword } from "../../components/NewPassword";
import {
  validateEmail,
  validatePhone,
  validatePassword,
} from "../../utils/helper";
import { SignIn } from "../../components/SignIn";

export const SignInPage = ({ loadingCallback }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [signinErrorMessage, setSigninErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSigninError, setIsSigninError] = useState(false);
  const history = useHistory();
  const onUserNameChange = (e) => {
    setUsername(e.target.value);
    if (isNaN(e.target.value) || e.target.value === "") {
      setShowCountryCode(false);
    } else {
      setShowCountryCode(true);
    }
    if (isNaN(e.target.value) && e.target.value === "") {
      setIsError(true);
      setErrorMessage("Enter a valid email  or username");
    } else {
      setIsError(false);
      setErrorMessage("");
    }
  };
  const onPasswordChange = (e) => {
    if (e.target.name === "new_password") {
      setNewPassword(e.target.value);
      if (!validatePassword(e.target.value)) {
        setIsPasswordError(true);
      } else {
        setIsPasswordError(false);
      }
    }
    if (e.target.name === "new_password_again") {
      setNewPasswordAgain(e.target.value);
      if (
        !validatePassword(e.target.value) ||
        newPassword.trim() !== e.target.value
      ) {
        setIsRepeatPasswordError(true);
      } else {
        setIsRepeatPasswordError(false);
      }
    }
  };
  const onNameChange = (e) => {
    if (e.target.name === "firstName") setFirstName(e.target.value);
    if (e.target.name === "familyName") setFamilyName(e.target.value);
  };
  const signIn = async (e) => {
    e.preventDefault();
    // if (isNaN(username) && !validateEmail(username)) {
    //   setIsError(true);
    //   setErrorMessage("Enter a valid email");
    //   return true;
    // }
    if (!isNaN(username) && !validatePhone(username)) {
      setIsError(true);
      setErrorMessage("Enter a valid phone number");
      return true;
    }
    try {
      const userName = isNaN(username) ? username : `${countryCode}${username}`;
      loadingCallback(true, "signing in...");
      const user = await Auth.signIn(userName, password);
      loadingCallback(false, "");
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(user);
        setIsNewPasswordRequired(true);
      } else {
        history.push("/");
      }
    } catch (err) {
      loadingCallback(false, "");
      setIsSigninError(true);
      setSigninErrorMessage(err.message);
    }
  };
  const onNewPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      loadingCallback(true, "Setting new password...");
      // eslint-disable-next-line no-unused-vars
      const response = await Auth.completeNewPassword(user, newPassword, {
        given_name: firstName,
        family_name: familyName,
      });
      loadingCallback(false, "");
      history.push("/");
    } catch (err) {
      loadingCallback(false, "");
      setIsSigninError(true);
      setSigninErrorMessage(err.message);
    }
  };
  return (
    <>
      {isNewPasswordRequired ? (
        <NewPassword
          firstName={firstName}
          familyName={familyName}
          newPassword={newPassword}
          newPasswordAgain={newPasswordAgain}
          isPasswordError={isPasswordError}
          isRepeatPasswordError={isRepeatPasswordError}
          onNameChange={onNameChange}
          onPasswordChange={onPasswordChange}
          onNewPasswordSubmit={onNewPasswordSubmit}
          showPassword={showNewPassword}
          toggleShowPassword={() => setShowNewPassword(!showNewPassword)}
        />
      ) : (
        <SignIn
          username={username}
          password={password}
          errorMessage={errorMessage}
          signinErrorMessage={signinErrorMessage}
          isSigninError={isSigninError}
          showCountryCode={showCountryCode}
          isError={isError}
          onUserNameChange={onUserNameChange}
          onPasswordChange={(e) => setPassword(e.target.value)}
          showPassword={showPassword}
          toggleShowPassword={() => setShowPassword(!showPassword)}
          codeChange={(country_code) => setCountryCode(country_code)}
          signIn={signIn}
        />
      )}
    </>
  );
};
