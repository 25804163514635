import React from "react";
import { Box, Flex, Button, Image } from "@chakra-ui/react";

export const QSetHeader = ({ onChangeDyno, disableNext, disablePrev }) => {
  return (
    <Box
      as="header"
      sx={{
        position: "sticky",
        top: "64px",
        background: "#fff",
        zIndex: 999,
        display: "block",
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="2"
        height="64px"
        borderBottom="1px solid #ccc"
        width="100%"
      >
        <Button
          colorScheme="blue"
          variant="ghost"
          fontSize="18px"
          onClick={() => onChangeDyno(false)}
          isDisabled={disablePrev}
        >
          <Image src="./images/left-arrow.png" alt="Previous" />
          Previous
        </Button>
        <Button
          colorScheme="blue"
          variant="ghost"
          fontSize="18px"
          onClick={() => onChangeDyno(true)}
          isDisabled={disableNext}
        >
          Next
          <Image src="./images/right-arrow.png" alt="Previous" />
        </Button>
      </Flex>
    </Box>
  );
};
