import React from "react";
import {
  Button,
  Heading,
  Stack,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
} from "@chakra-ui/react";
import { CountryCodeSelect } from "../CountryCodeSelect";

export const ForgotPassword = ({
  username,
  errorMessage,
  showCountryCode,
  onUsernameChange,
  isError,
  onForgotPasswordSubmit,
  codeChange,
}) => {
  return (
    <>
      <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
        Forgot your password?
      </Heading>
      <Text fontSize={{ base: "sm", sm: "md" }} color="gray.800">
        Enter your Email or Phone number below and we will send a message to
        reset your password
      </Text>
      <form onSubmit={onForgotPasswordSubmit}>
        <FormControl
          padding="0 0 10px"
          colorScheme="brand"
          isRequired={true}
          isInvalid={isError}
        >
          <FormLabel>Email or Phone number</FormLabel>
          <InputGroup>
            {showCountryCode && <CountryCodeSelect codeChange={codeChange} />}
            <Input
              type="text"
              name="username"
              placeholder="Email or Phone number"
              value={username}
              errorBorderColor="red.300"
              onChange={onUsernameChange}
            />
          </InputGroup>
          <FormErrorMessage display="block">{errorMessage}</FormErrorMessage>
        </FormControl>
        <Stack spacing={6}>
          <Button
            colorScheme="brand"
            type="submit"
            _hover={{
              bg: "blue.500",
            }}
          >
            Reset my password
          </Button>
        </Stack>
      </form>
    </>
  );
};
