import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";

export const LoginButton = ({ styleProps }) => {
  const history = useHistory();
  const login = () => {
    history.push("/login");
  };
  return (
    <Button colorScheme="blue" {...styleProps} onClick={login}>
      Login
    </Button>
  );
};
