import { gql } from "@apollo/client";
import { myDynosFields, submissionsVariables } from "../fields";

export const USER = gql`
  query Users($where: IndParentWhereInput) {
    indParents(where: $where) {
      id
      pupil {
        id
        firstName
        lastName
        username
        email
        phone
        otherInfo
      }
      parent {
        id
        firstName
        lastName
        username
        email
        phone
      }
    }
  }
`;

export const REQUESTSTORES = gql`
  query RequestStores {
    constants {
      id
      name
      value
      enabled
    }
    functions {
      id
      name
      space
      description
      example
      arguments {
        id
        name
        type
        isOptional
      }
    }
    patterns {
      id
      patternName
      patternCode
    }
    numberings {
      id
      name
      units
    }
    dynoStatuses {
      id
      statusType
    }
    dynoTypes {
      id
      name
      description
    }
  }
`;

export const DYNO = gql`
  query Dyno($where: DynoWhereUniqueInput!, $submission_where: SubmissionWhereInput) {
    dyno(where: $where) {
      ${myDynosFields}
      submissions (orderBy: {updatedAt: desc}, take: 1, where: $submission_where) {
        ${submissionsVariables}
      }
    }
  }
`;

export const SUBMISSIONS = gql`
  query Submissions($where: SubmissionWhereInput) {
    submissions(where: $where) {
      id
      dyno {
        id
      }
    }
  }
`;

export const QSET = gql`
  query Qset(
    $where: QSetWhereUniqueInput!
    $submissionWhere: QSetSubmissionWhereInput
  ) {
    qset(where: $where) {
      id
      qSetName
      qSetSubmissions(
        where: $submissionWhere
        orderBy: { createdAt: desc }
        take: 1
      ) {
        id
        isAllQuestionSubmitted
        lastDynoAttempted {
          id
        }
      }
      dynosUnderQSet {
        id
      }
    }
  }
`;

export const QAPP = gql`
  query Qapp($where: QAppWhereUniqueInput!, $qsetwhere: QSetWhereInput) {
    qapp(where: $where) {
      id
      app {
        id
        bundleName
        bundleDescription
        products(orderBy: { index: asc }) {
          id
          index
          productName
        }
        bundleAppProp {
          id
          questionPickMode
          maxNumberOfQs
          preReqs
          badges(orderBy: { index: asc }) {
            id
            criteria
            badgeName
            badgeImage
          }
        }
      }
      levelStatus
      user {
        id
        username
      }
      myBadges {
        id
        badgeName
        badgeImage
      }
      qsets(take: 3, orderBy: { createdAt: desc }) {
        id
        qSetName
        qSetSubmissions(
          where: { isAllQuestionSubmitted: { equals: true } }
          orderBy: { createdAt: desc }
          take: 1
        ) {
          id
          qsetScore
          qsetTimeTaken
          isAllQuestionSubmitted
          updatedAt
          qset {
            dynosUnderQSet {
              id
            }
          }
        }
      }
    }
    qsets(where: $qsetwhere, orderBy: { createdAt: desc }, take: 1) {
      id
      qSetName
    }
  }
`;

export const PRODUCTS = gql`
  query Qapp($where: QAppWhereUniqueInput!) {
    qapp(where: $where) {
      id
      app {
        id
        bundleAppProp {
          id
          questionPickMode
          maxNumberOfQs
        }
        products(orderBy: { index: asc }) {
          id
          index
          productName
          productCoverPath
        }
      }
    }
  }
`;

export const QSETS = gql`
  query Qsets($where: QSetWhereInput) {
    qsets(where: $where, orderBy: { createdAt: desc }) {
      id
      qSetName
      qSetSubmissions(
        where: { isAllQuestionSubmitted: { equals: true } }
        orderBy: { createdAt: desc }
        take: 1
      ) {
        id
        qsetScore
        qsetTimeTaken
        isAllQuestionSubmitted
        updatedAt
        qset {
          dynosUnderQSet {
            id
          }
        }
      }
    }
  }
`;

export const QSETSUBMISSION = gql`
  query QSetSubmission($where: QSetSubmissionWhereInput) {
    qSetSubmission(where: $where) {
      id
      qsetScore
      isAllQuestionSubmitted
      qsetTimeTaken
      qset {
        dynosUnderQSet {
          id
        }
      }
    }
  }
`;

export const USERQAPPREPORT = gql`
  query UserQAppReport($where: UserBadgesWhereInput!) {
    userQAppReport(where: $where) {
      level
      numberOfQuestionsAttempted
      avgTimeTakenPerQuestion
      correctlyAnswered
    }
  }
`;
